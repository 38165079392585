import { PageTemplate } from "../components/PageTemplate";
import { withStore } from "../libs/mobx/withStore";
import { graphql } from "gatsby";
import React from "react";

const Page = ({ data, location }) => {
  const {
    wpPage: { pageFields, seo, slug },
    wp: { themeGeneralSettings, generalSettings },
  } = data;

  const blocks = pageFields.page || pageFields.page;
  const options = themeGeneralSettings;

  return (
    <PageTemplate
      blocks={blocks}
      options={options}
      location={location}
      siteMeta={generalSettings}
      yoast={seo}
      slug={slug}
    />
  );
};
// In development, disable seo query to avoid errors

export const pageQuery = graphql`
  query PageById($id: String!) {
    wpPage(id: { eq: $id }) {
      ...PageFragment
      slug
      seo {
        title
        metaDesc
        canonical
        opengraphImage {
          sourceUrl
        }
      }
    }
    wp {
      ...FooterFragment
      ...FormFragment
      generalSettings {
        title
        description
        url
      }
    }
  }
`;

export default withStore(Page);
